
import { computed, defineComponent, onMounted, onUnmounted, ref, watch } from 'vue';

import SocialMediaIconBox from '../../../components/Frontend/SocialMediaIconBox.vue';
import Footer from '../../../components/Frontend/Footer.vue';
import { mdiBeakerAlert, mdiChevronLeft, mdiChevronRight, mdiCursorDefaultClick, mdiInformationOutline } from '@mdi/js';

export default defineComponent({
  meta: {
    de: {
      title: 'Geschichte der STYRIA. Seit 1869.| Styria Media Group',
      keywords: ['unternehmensgeschihte', 'geschichte', 'historie', 'styria', '1869'],
      description: 'Zukunft braucht Herkunft: Die STYRIA bringt ihre Medien seit 1869 den Menschen näher.',
      image: require('@/assets/img/og/ueber-uns.png'),
    },
    en: {
      title: 'History. Since 1869. | Styria Media Group',
      keywords: ['corporate history', 'history', 'styria', '1869'],
      description: 'Future needs origin: STYRIA has been bringing its media closer to the people since 1869.',
      image: require('@/assets/img/og/ueber-uns.png'),
    },
  },
  components: {
    SocialMediaIconBox,
    Footer,
  },
  setup() {
    const active = ref(0);
    const containerwidth = ref(0);
    const boxwidth = ref(0);
    const boxcount = ref(0);
    const itemcount = ref(0);
    const swiping = ref(0);

    const windowsize = ref(window.innerWidth);
    const noslide = computed(() => windowsize.value <= 768);

    const overlayHide = ref(false);
    const toogleOverlay = () => {
      overlayHide.value = !overlayHide.value;
    };
    const setOverlay = (value: boolean) => {
      overlayHide.value = value;
    };

    let sliderbar: HTMLElement | null;
    let yearbar: HTMLElement | null;
    let yearcontainer: HTMLElement | null;

    const translate = computed(() => {
      if (noslide.value) return 0;
      if (boxwidth.value > 0) {
        let offset = 0;
        if (swiping.value == 1) {
          offset = -50;
        } else if (swiping.value == -1) {
          offset = 50;
        }
        return active.value * boxwidth.value + offset;
      }

      return 0;
    });

    const percentscrolled = computed(() => {
      return (active.value * boxwidth.value) / ((boxwidth.value * (boxcount.value - itemcount.value)) / 100); // current % * (100% / 100)
    });

    const yearoffset = (base: number) => {
      if (percentscrolled.value < 50) {
        return (base * percentscrolled.value) / 50;
      } else if (percentscrolled.value > 50) {
        return -1 * ((base * (100 - percentscrolled.value)) / 50);
      } else {
        return base;
      }
    };

    const updateslider = () => {
      if (sliderbar != null && yearbar != null && yearcontainer != null) {
        const sliderbarbase = sliderbar.clientWidth / 2;
        const yearbarbase = 150 / 2 - sliderbarbase;

        sliderbar.style.left = `${percentscrolled.value}%`;

        if (percentscrolled.value <= 50) {
          sliderbar.style.transform = `translateX(-${(percentscrolled.value / 100) * sliderbar.clientWidth - yearoffset(sliderbarbase)}px)`;
          yearbar.style.transform = `translateX(-${(percentscrolled.value / 100) * (yearbar.clientWidth - yearcontainer.clientWidth) + (yearbarbase - (yearbarbase * percentscrolled.value) / 50 - sliderbar.clientWidth / 2)}px)`;
        } else {
          sliderbar.style.transform = `translateX(-${(percentscrolled.value / 100) * sliderbar.clientWidth - yearoffset(sliderbarbase)}px)`;
          yearbar.style.transform = `translateX(-${(percentscrolled.value / 100) * (yearbar.clientWidth - yearcontainer.clientWidth) + (yearbarbase - (yearbarbase * percentscrolled.value) / 50 + sliderbar.clientWidth / 2)}px)`;
        }
      }
    };
    watch(active, updateslider);

    const right = () => {
      if (active.value > 0) {
        active.value--;
        //updateslider();
        setOverlay(true);
      }
    };

    const left = () => {
      if (active.value < boxcount.value - itemcount.value) {
        active.value++;
        // updateslider();
        setOverlay(true);
      }
    };

    const toEnd = () => {
      active.value = boxcount.value - itemcount.value;
      setOverlay(true);
    };

    const toStart = () => {
      active.value = 0;
      setOverlay(true);
    };

    const handlekey = (e: KeyboardEvent) => {
      switch (e.key) {
        case 'ArrowLeft':
          right();
          break;
        case 'ArrowRight':
          left();
          break;
      }
    };

    const init = () => {
      const elements = document.getElementsByClassName('box-column');
      const container = document.getElementById('slider-inner');
      const viewport = document.getElementById('slider-outer');
      sliderbar = document.getElementById('slider-bar');
      yearbar = document.getElementById('slider-navi-inner');
      yearcontainer = document.getElementById('slider-container');

      if (elements.length > 0 && container && viewport && yearbar) {
        if (noslide.value) {
          boxwidth.value = elements[0].clientWidth;
          boxcount.value = elements.length;
          container.style.width = `100%`;
          containerwidth.value = boxcount.value * boxwidth.value;
          itemcount.value = viewport.clientWidth / boxwidth.value;
          yearbar.style.width = `${(boxcount.value - itemcount.value + 1) * 150}px`;
          updateslider();
        } else {
          boxwidth.value = elements[0].clientWidth;
          boxcount.value = elements.length;
          container.style.width = `${boxcount.value * boxwidth.value}px`;
          containerwidth.value = boxcount.value * boxwidth.value;
          itemcount.value = viewport.clientWidth / boxwidth.value;
          yearbar.style.width = `${(boxcount.value - itemcount.value + 1) * 150}px`;
          updateslider();
        }
      }
      window.addEventListener('keyup', handlekey);
    };

    let touchstartx = 0;

    const unify = (e: TouchEvent | MouseEvent) => {
      return (e as TouchEvent).changedTouches ? (e as TouchEvent).changedTouches[0] : (e as MouseEvent);
    };

    const touchstart = (e: TouchEvent | MouseEvent) => {
      if (noslide.value) return;
      touchstartx = unify(e).clientX;
    };

    const touchend = (e: TouchEvent | MouseEvent) => {
      if (noslide.value) return;
      if (touchstartx || touchstartx === 0) {
        if (Math.abs(unify(e).clientX - touchstartx) > 50) {
          const direction = Math.sign(unify(e).clientX - touchstartx);
          if (direction == -1) {
            left();
          } else if (direction == 1) {
            right();
          }
        }
        swiping.value = 0;
        touchstartx = 0;
      }
    };

    const touchmove = (e: TouchEvent | MouseEvent) => {
      if (noslide.value) return;
      e.preventDefault();
      if (touchstartx || touchstartx != 0) {
        if (Math.abs(unify(e).clientX - touchstartx) > 50) {
          swiping.value = Math.sign(unify(e).clientX - touchstartx);
        }
      }
    };

    const handleResize = () => {
      windowsize.value = window.innerWidth;
      init();
    };

    window.addEventListener('resize', handleResize);

    const cleanup = () => {
      window.removeEventListener('keyup', handlekey);
      window.removeEventListener('resize', handleResize);
    };

    onMounted(init);
    onUnmounted(cleanup);

    return {
      windowsize,
      mdiChevronLeft,
      translate,
      mdiChevronRight,
      mdiCursorDefaultClick,
      mdiInformationOutline,
      left,
      right,
      touchstart,
      touchend,
      touchmove,
      toEnd,
      toStart,
      swiping,
      toogleOverlay,
      overlayHide,
      setOverlay,
    };
  },
});

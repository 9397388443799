<style lang="scss">
#debug {
  display: none;
}
#frontend {
  .geschichte-all {
    $max-width-viewport: 1500px;
    .socialmediaiconbox-outer {
      @media (max-width: 1640px) {
        display: none;
      }
    }
    ::-webkit-scrollbar {
      height: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 50px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    .toolge-overlay {
      z-index: 10;
      max-width: $max-width-viewport;
      margin-left: auto;
      margin-right: auto;
      padding-bottom: 20px;
      @media (max-width: $width-md) {
        display: none;
      }
      span {
        display: inline-block;
        padding-left: 30px;
        cursor: pointer;
        svg {
          width: 40px;
          height: 40px;
          fill: $coral;
        }
        &:hover {
          svg {
            fill: #333;
          }
        }
      }
    }
    .slider-viewport {
      //border:1px solid red;
      max-width: $max-width-viewport;
      width: 100%;
      margin: 0 auto;
      overflow-x: hidden;
      position: relative;
      @media (max-width: $width-md) {
        //overflow-x: auto;
        margin-top: 50px;
      }

      .overlay-info {
        position: absolute;
        width: 100%;
        height: 97%;
        transition: all 400ms ease;
        z-index: 10;
        @media (max-width: $width-md) {
          display: none;
        }
        .overlay-bg {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          z-index: 5;
          background-color: rgba($color: #fff, $alpha: 0.7);
        }
        .overlay-inner {
          position: absolute;
          left: 0;
          top: 0;
          width: 300px;
          height: 300px;
          z-index: 5;
          &:after {
            content: '';
            display: block;
            width: 450px;
            height: 450px;
            position: absolute;
            left: -130px;
            top: -130px;
            background-color: rgba($color: #fff, $alpha: 1);
            z-index: 12;
            border-radius: 50%;
          }
          &:before {
            content: '';
            display: block;
            width: 470px;
            height: 470px;
            position: absolute;
            left: -135px;
            top: -135px;
            background-color: rgba($color: $coral, $alpha: 1);
            z-index: 2;
            border-radius: 50%;
          }

          .txt {
            position: relative;

            padding: 50px 10px 0 30px;
            max-width: 300px;
            letter-spacing: 1px;
            font-size: 90%;
            z-index: 14;

            svg {
              display: block;
              margin-bottom: 20px;
              fill: #333;
              height: 40px;
              width: 40px;
              animation: pulse-arrow 2s infinite;
            }
          }
        }

        @keyframes pulse-arrow {
          0% {
            transform: scale(1);
          }

          70% {
            transform: scale(0.9);
          }

          100% {
            transform: scale(1);
          }
        }
      }
      .overlayAnimation-enter-active,
      .overlayAnimation-leave-active {
        transition: all 400ms ease;
        //transform: scale(1);
        transform-origin: left top;
      }

      .overlayAnimation-enter-from,
      .overlayAnimation-leave-to {
        opacity: 0;
        transform: scale(0);
        transform: translateX(-100%);
        transform-origin: left top;
      }

      .slider-outer {
        .slider-inner {
          //border:1px solid blue;
          display: flex;
          //flex-flow: column;
          transition: 0.5s transform;
          height: 620px;
          @media (max-width: $width-md) {
            flex-flow: column;
            height: auto;
            padding-bottom: 20px;
          }
          .box-column {
            pointer-events: none;

            @media (max-width: $width-md) {
              display: flex;
              flex-flow: column;
            }
            .box {
              //background:#eee;
              width: 300px;
              height: 300px;
              @media (max-width: $width-md) {
                margin: 0 auto;
              }

              .box-inner {
                width: 100%;
                height: 300px;
                .txt-box {
                  //border:1px solid red;
                  width: 100%;
                  height: 300px;
                  padding: 20px;
                  position: relative;
                  h3 {
                    display: inline-block;
                    font-family: 'Helvetica Neue LT W05_75 Bold';

                    font-size: 130%;
                    line-height: 1.5;

                    @media (max-width: $width-sm) {
                      margin-bottom: 0 !important;
                    }
                    span {
                      //border-bottom: 3px solid #000;
                    }
                  }
                  p {
                    font-size: 90%;
                    line-height: 1.5;
                  }
                  .txt-small {
                    font-size: 80%;
                  }
                  .zahl {
                    display: block;
                    font-family: 'Helvetica Neue LT W05 25 Ult Lt';
                    color: #fff;
                    font-size: 90px;
                    letter-spacing: 1px;
                    position: absolute;
                    line-height: 1;
                    bottom: 5px;
                    left: 15px;
                  }
                }
              }
              .bg-blue-60perc {
                background: $blue-60per;
              }
              .bg-violet {
                background: $violet-50per;
              }
              .bg-mint {
                background: $mint;
              }
              .bg-grey {
                background: #ebebeb;
              }
              .img-box {
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center center;
              }
            }
          }
          .box-column-order {
            @media (max-width: 1920px) {
              .box {
                &:last-child {
                  order: 1;
                  -webkit-order: 1;
                }
                &:first-child {
                  order: 2;
                  -webkit-order: 2;
                }
              }
            }
          }
        }
      }
    }
    .slider-navi-all {
      margin-top: 50px;
      margin-bottom: 200px;
      position: relative;

      @media (max-width: $width-md) {
        display: none;
      }

      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 86px;
        position: absolute;
        z-index: -1;
        left: 0;
        top: 25px;
        border-top: 3px solid #c5c5c5;
        border-bottom: 3px solid #c5c5c5;
      }
    }
    .slider-navi-viewport {
      //border:1px solid red;
      max-width: $max-width-viewport;
      margin: 0px auto;
      overflow-x: hidden;
      height: 190px;
      $width-navi-all: 3450px;

      ::-webkit-scrollbar {
        height: 10px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 50px;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
      .slider-navi-outer {
        position: relative;
        overflow-x: hidden;

        .slider-bar {
          position: absolute;
          transition: all 0.2s;
          top: 30px;
          z-index: 3;
          display: inline-block;

          .slider-bar-inner {
            //border:1px solid red;
            //position: absolute;

            //background:blue;
            //border-top:2px solid #c5c5c5;
            //border-bottom:2px solid #c5c5c5;
            width: 36px;
            height: 80px;

            .slider-btn-outer {
              background: $coral;
              width: 36px;
              height: 76px;
              position: absolute;
              top: -1px;

              .slider-btn {
                width: 40px;
                height: 38px;
                line-height: 38px;
                cursor: pointer;

                text-align: center;
                //border:1px solid red;
                svg {
                  width: 25px;
                  height: 25px;
                  position: relative;
                  top: -3px;
                }
              }
              .slider-btn-next {
                svg {
                  left: 40px;
                }
              }
              .slider-btn-prev {
                svg {
                  left: -40px;
                }
              }
            }
          }
        }

        .slider-navi-inner {
          display: flex;
          flex: nowrap;
          transition: 0.5s transform;

          height: 144px;
          padding-top: 30px;
          &:after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background: #c5c5c5;
            position: absolute;
            top: 50%;
            margin-top: -3px;
            left: 0;
            z-index: -1;
          }

          .navi-column {
            position: relative;

            height: 80px;
            width: 150px;

            span {
              position: absolute;
              display: block;
              width: 100%;
              //border:1px solid blue;
              text-align: center;
              font-family: 'Helvetica Neue LT W05 45 Light';
              letter-spacing: 2px;
            }
            .nr-top {
              top: -30px;
            }
            .nr-bottom {
              bottom: -30px;
            }
          }
        }
      }
    }
    .btn-jump-to {
      max-width: $max-width-viewport;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      height: 50px;
      @media (max-width: $width-md) {
        display: none;
      }
      .btn-jump {
        display: inline-block;
        position: absolute;
        line-height: 50px;
        text-transform: uppercase;
        letter-spacing: 2px;
        background: $coral;
        cursor: pointer;
        color: #fff;

        svg {
          width: 30px;
          height: 30px;
          position: relative;
          top: -2px;
          fill: #fff;
        }
        &:hover {
          opacity: 0.7;
          svg {
          }
        }
      }
      .btn-start {
        left: 0;
        top: 0;
        padding: 0 20px 0 10px;
      }
      .btn-end {
        right: 0;
        top: 0;
        padding: 0 10px 0 20px;
      }
    }
  }
}
</style>

<template>
  <div class="geschichte-all" @keypress.left="left" @keypress.right="right">
    <SocialMediaIconBox />
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="headline-box-std">
            <div class="headline-inner">
              <div class="breadcrumb">
                <span>{{ $t('General.ueber-uns') }}</span>
              </div>
              <h1 v-html="$t('geschichte.zukunft-braucht')"></h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row justify-content-center margin-b-s">
        <div class="col-lg-6 col-10">
          <p>{{ $t('geschichte.intro-txt') }}</p>
        </div>
      </div>
    </div>

    <div class="toolge-overlay" @click="toogleOverlay">
      <span><Icon :path="mdiInformationOutline" /> </span>
    </div>
    <div class="slider-viewport" @mouseenter="setOverlay(true)">
      <transition name="overlayAnimation">
        <div class="overlay-info" v-if="!overlayHide">
          <div class="overlay-bg"></div>
          <div class="overlay-inner">
            <div class="txt">
              <Icon :path="mdiCursorDefaultClick" />
              <div class="txt-device-big">
                {{ $t('geschichte.info-navigation') }}
              </div>
            </div>
          </div>
        </div>
      </transition>
      <div class="slider-outer" id="slider-outer" @touchstart="touchstart" @mousedown="touchstart" @touchend="touchend" @mouseup="touchend" @mousemove="touchmove" @touchmove="touchmove">
        <div class="slider-inner" id="slider-inner" :style="`transform: translateX(-${translate}px)`">
          <div class="box-column box-column-order" data-year="2021">
            <div class="box">
              <div class="box-inner img-box" :style="{ 'background-image': `url(${require('@/assets/img/ueber-uns/geschichte/2019-styria.png')})` }"></div>
            </div>
            <div class="box">
              <div class="box-inner bg-blue-60perc">
                <div class="txt-box">
                  <h3>
                    <span>{{ $t('geschichte.2021-hl') }}</span>
                  </h3>
                  <p>{{ $t('geschichte.2021-txt') }}</p>
                  <span class="zahl">2021</span>
                </div>
              </div>
            </div>
          </div>
          <div class="box-column" data-year="2021">
            <div class="box">
              <div class="box-inner bg-mint">
                <div class="txt-box">
                  <h3>
                    <span>{{ $t('geschichte.2021-hl-v2') }}</span>
                  </h3>
                  <p>{{ $t('geschichte.2021-txt-v2') }}</p>
                  <span class="zahl">2021</span>
                </div>
              </div>
            </div>
            <div class="box">
              <div class="box-inner img-box" :style="{ 'background-image': `url(${require('@/assets/img/ueber-uns/geschichte/2021-radio-flamingo.jpg')})` }"></div>
            </div>
          </div>

          <div class="box-column box-column-order" data-year="2019">
            <div class="box">
              <div class="box-inner img-box" :style="{ 'background-image': `url(${require('@/assets/img/ueber-uns/geschichte/2019-styria.jpg')})` }"></div>
            </div>
            <div class="box">
              <div class="box-inner bg-violet">
                <div class="txt-box">
                  <h3>
                    <span>{{ $t('geschichte.2019-hl') }}</span>
                  </h3>
                  <p>{{ $t('geschichte.2019-txt') }}</p>
                  <span class="zahl">2019</span>
                </div>
              </div>
            </div>
          </div>
          <div class="box-column" data-year="2015">
            <div class="box">
              <div class="box-inner bg-mint">
                <div class="txt-box">
                  <h3>
                    <span>{{ $t('geschichte.2015-hl') }}</span>
                  </h3>
                  <p>{{ $t('geschichte.2015-txt') }}</p>
                  <span class="zahl">2015</span>
                </div>
              </div>
            </div>
            <div class="box">
              <div class="box-inner img-box" :style="{ 'background-image': `url(${require('@/assets/img/ueber-uns/geschichte/2015-neues-haus.jpg')})` }"></div>
            </div>
          </div>
          <div class="box-column box-column-order" data-year="2006">
            <div class="box">
              <div class="box-inner img-box" :style="{ 'background-image': `url(${require('@/assets/img/ueber-uns/geschichte/2005-willhaben.jpg')})` }"></div>
            </div>
            <div class="box">
              <div class="box-inner bg-blue-60perc">
                <div class="txt-box">
                  <h3>
                    <span>{{ $t('geschichte.2006-hl') }}</span>
                  </h3>
                  <p>{{ $t('geschichte.2006-txt') }}</p>
                  <span class="zahl">2006</span>
                </div>
              </div>
            </div>
          </div>
          <div class="box-column" data-year="2005">
            <div class="box">
              <div class="box-inner bg-violet">
                <div class="txt-box">
                  <h3>
                    <span>{{ $t('geschichte.2005-hl') }}</span>
                  </h3>
                  <p>{{ $t('geschichte.2005-txt') }}</p>
                  <span class="zahl">2005</span>
                </div>
              </div>
            </div>
            <div class="box">
              <div class="box-inner img-box" :style="{ 'background-image': `url(${require('@/assets/img/ueber-uns/geschichte/2005-24sata.jpg')})` }" style="background-position:top center;"></div>
            </div>
          </div>
          <div class="box-column box-column-order" data-year="2002">
            <div class="box">
              <div class="box-inner img-box" :style="{ 'background-image': `url(${require('@/assets/img/ueber-uns/geschichte/2002-Carinthia-Druckzentrum.jpg')})` }"></div>
            </div>
            <div class="box">
              <div class="box-inner bg-mint">
                <div class="txt-box">
                  <h3>
                    <span>{{ $t('geschichte.2002-hl') }}</span>
                  </h3>
                  <p>{{ $t('geschichte.2002-txt') }}</p>
                  <span class="zahl">2002</span>
                </div>
              </div>
            </div>
          </div>
          <div class="box-column" data-year="2001">
            <div class="box">
              <div class="box-inner bg-blue-60perc">
                <div class="txt-box">
                  <h3>
                    <span>{{ $t('geschichte.2001-hl') }}</span>
                  </h3>
                  <p>{{ $t('geschichte.2001-txt') }}</p>
                  <span class="zahl">2001</span>
                </div>
              </div>
            </div>
            <div class="box">
              <div class="box-inner img-box" :style="{ 'background-image': `url(${require('@/assets/img/ueber-uns/geschichte/2001-Vecernji-List2.jpg')})` }" style="background-position:top center;"></div>
            </div>
          </div>
          <div class="box-column box-column-order" data-year="1997">
            <div class="box">
              <div class="box-inner img-box" :style="{ 'background-image': `url(${require('@/assets/img/ueber-uns/geschichte/1997-Aktiengesellschaft-Pirker-Haberfellner-Kainz.jpg')})` }"></div>
            </div>
            <div class="box">
              <div class="box-inner bg-violet">
                <div class="txt-box">
                  <h3>
                    <span>{{ $t('geschichte.1997-hl') }}</span>
                  </h3>
                  <p>{{ $t('geschichte.1997-txt') }}</p>
                  <span class="zahl">1997</span>
                </div>
              </div>
            </div>
          </div>
          <div class="box-column" data-year="1995">
            <div class="box">
              <div class="box-inner bg-mint">
                <div class="txt-box">
                  <h3>
                    <span>{{ $t('geschichte.1995-hl') }}</span>
                  </h3>
                  <p>{{ $t('geschichte.1995-txt') }}</p>
                  <span class="zahl">1995</span>
                </div>
              </div>
            </div>
            <div class="box">
              <div class="box-inner img-box" :style="{ 'background-image': `url(${require('@/assets/img/ueber-uns/geschichte/1995-Antenne-Steiermark.jpg')})` }"></div>
            </div>
          </div>
          <div class="box-column box-column-order" data-year="1992">
            <div class="box">
              <div class="box-inner img-box" :style="{ 'background-image': `url(${require('@/assets/img/ueber-uns/geschichte/1992-Druckzentrum-Messendorf.jpg')})` }"></div>
            </div>
            <div class="box">
              <div class="box-inner bg-blue-60perc">
                <div class="txt-box">
                  <h3>
                    <span>{{ $t('geschichte.1992-hl') }}</span>
                  </h3>
                  <p>{{ $t('geschichte.1992-txt') }}</p>
                  <span class="zahl">1992</span>
                </div>
              </div>
            </div>
          </div>
          <div class="box-column" data-year="1991">
            <div class="box">
              <div class="box-inner bg-violet">
                <div class="txt-box">
                  <h3>
                    <span>{{ $t('geschichte.1991-hl') }}</span>
                  </h3>
                  <p>{{ $t('geschichte.1991-txt') }}</p>
                  <span class="zahl">1991</span>
                </div>
              </div>
            </div>
            <div class="box">
              <div class="box-inner img-box" :style="{ 'background-image': `url(${require('@/assets/img/ueber-uns/geschichte/1991-Die-Presse.jpg')})` }"></div>
            </div>
          </div>
          <div class="box-column box-column-order" data-year="1980">
            <div class="box">
              <div class="box-inner img-box" :style="{ 'background-image': `url(${require('@/assets/img/ueber-uns/geschichte/1970er-80er-Mayr0005.jpg')})` }" style="background-position:top center;"></div>
            </div>
            <div class="box">
              <div class="box-inner bg-mint">
                <div class="txt-box">
                  <h3>
                    <span>{{ $t('geschichte.19701980-hl') }}</span>
                  </h3>
                  <p>{{ $t('geschichte.19701980-txt') }}</p>
                  <span class="zahl" v-html="$t('geschichte.19701980-zahl')"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="box-column " data-year="1977">
            <div class="box">
              <div class="box-inner bg-blue-60perc">
                <div class="txt-box">
                  <h3>
                    <span>{{ $t('geschichte.1977-hl') }}</span>
                  </h3>
                  <p>{{ $t('geschichte.1977-txt') }}</p>
                  <span class="zahl">1977</span>
                </div>
              </div>
            </div>
            <div class="box">
              <div class="box-inner img-box" :style="{ 'background-image': `url(${require('@/assets/img/ueber-uns/geschichte/1977-neue-technik.jpg')})` }"></div>
            </div>
          </div>
          <div class="box-column box-column-order" data-year="1960">
            <div class="box">
              <div class="box-inner img-box" :style="{ 'background-image': `url(${require('@/assets/img/ueber-uns/geschichte/1960er-Frankfurter-Buchmesse.jpg')})` }"></div>
            </div>
            <div class="box">
              <div class="box-inner bg-violet">
                <div class="txt-box">
                  <h3><span v-html="$t('geschichte.1960-hl')"></span></h3>
                  <p>{{ $t('geschichte.1960-txt') }}</p>
                  <span class="zahl">1960er</span>
                </div>
              </div>
            </div>
          </div>
          <div class="box-column" data-year="1948">
            <div class="box">
              <div class="box-inner bg-mint">
                <div class="txt-box">
                  <h3>
                    <span>{{ $t('geschichte.1948-hl') }}</span>
                  </h3>
                  <p>{{ $t('geschichte.1948-txt') }}</p>
                  <span class="zahl">1948</span>
                </div>
              </div>
            </div>
            <div class="box">
              <div class="box-inner img-box" :style="{ 'background-image': `url(${require('@/assets/img/ueber-uns/geschichte/1948-Kleine-Zeitung.jpg')})` }"></div>
            </div>
          </div>
          <div class="box-column box-column-order" data-year="1945">
            <div class="box">
              <div class="box-inner img-box" :style="{ 'background-image': `url(${require('@/assets/img/ueber-uns/geschichte/1945_Karl-Maria-Stepan.jpg')})` }"></div>
            </div>
            <div class="box">
              <div class="box-inner bg-blue-60perc">
                <div class="txt-box">
                  <h3>
                    <span>{{ $t('geschichte.1945-hl') }}</span>
                  </h3>
                  <p>{{ $t('geschichte.1945-txt') }}</p>
                  <span class="zahl">1945</span>
                </div>
              </div>
            </div>
          </div>
          <div class="box-column" data-year="1945">
            <div class="box">
              <div class="box-inner bg-violet">
                <div class="txt-box">
                  <h3>
                    <span>{{ $t('geschichte.1945-v2-hl') }}</span>
                  </h3>
                  <p>{{ $t('geschichte.1945-v2-txt') }}</p>
                  <span class="zahl">1945</span>
                </div>
              </div>
            </div>
            <div class="box">
              <div class="box-inner img-box" :style="{ 'background-image': `url(${require('@/assets/img/ueber-uns/geschichte/1945_Bombentreffer-Styria-1945.jpg')})` }"></div>
            </div>
          </div>
          <div class="box-column box-column-order" data-year="1938">
            <div class="box">
              <div class="box-inner img-box" :style="{ 'background-image': `url(${require('@/assets/img/ueber-uns/geschichte/1938_Gleichschaltung.jpg')})` }"></div>
            </div>
            <div class="box">
              <div class="box-inner bg-mint">
                <div class="txt-box">
                  <h3>
                    <span>{{ $t('geschichte.1938-hl') }}</span>
                  </h3>
                  <p>{{ $t('geschichte.1938-txt') }}</p>
                  <span class="zahl">1938</span>
                </div>
              </div>
            </div>
          </div>
          <div class="box-column" data-year="1912">
            <div class="box">
              <div class="box-inner bg-blue-60perc">
                <div class="txt-box">
                  <h3>
                    <span>{{ $t('geschichte.1912-hl') }}</span>
                  </h3>
                  <p>{{ $t('geschichte.1912-txt') }}</p>
                  <span class="zahl">1912</span>
                </div>
              </div>
            </div>
            <div class="box">
              <div class="box-inner img-box" :style="{ 'background-image': `url(${require('@/assets/img/ueber-uns/geschichte/1912_Frauen-in-der-Styria.jpg')})` }"></div>
            </div>
          </div>
          <div class="box-column box-column-order" data-year="1904">
            <div class="box">
              <div class="box-inner img-box" :style="{ 'background-image': `url(${require('@/assets/img/ueber-uns/geschichte/1904_Kleine-Zeitung.jpg')})` }" style="background-position:top center;"></div>
            </div>
            <div class="box">
              <div class="box-inner bg-violet">
                <div class="txt-box">
                  <h3>
                    <span>{{ $t('geschichte.1904-hl') }}</span>
                  </h3>
                  <p>{{ $t('geschichte.1904-txt') }}</p>
                  <span class="zahl">1904</span>
                </div>
              </div>
            </div>
          </div>
          <div class="box-column" data-year="1902">
            <div class="box">
              <div class="box-inner bg-blue-60perc">
                <div class="txt-box">
                  <h3>
                    <span>{{ $t('geschichte.1902-hl') }}</span>
                  </h3>
                  <p>{{ $t('geschichte.1902-txt') }}</p>
                  <span class="zahl">1902</span>
                </div>
              </div>
            </div>
            <div class="box">
              <div class="box-inner img-box" :style="{ 'background-image': `url(${require('@/assets/img/ueber-uns/geschichte/1902_Schoenaugasse.jpg')})` }"></div>
            </div>
          </div>
          <div class="box-column box-column-order" data-year="1895">
            <div class="box">
              <div class="box-inner img-box" :style="{ 'background-image': `url(${require('@/assets/img/ueber-uns/geschichte/1895_Rotationsdruckmaschine.jpg')})` }"></div>
            </div>
            <div class="box">
              <div class="box-inner bg-violet">
                <div class="txt-box">
                  <h3>
                    <span>{{ $t('geschichte.1895-hl') }}</span>
                  </h3>
                  <p>{{ $t('geschichte.1895-txt') }}</p>
                  <span class="zahl">1895</span>
                </div>
              </div>
            </div>
          </div>
          <div class="box-column" data-year="1890">
            <div class="box">
              <div class="box-inner bg-mint">
                <div class="txt-box">
                  <h3>
                    <span>{{ $t('geschichte.1890-hl') }}</span>
                  </h3>
                  <p v-html="$t('geschichte.1890-txt')"></p>
                  <span class="zahl">1890</span>
                </div>
              </div>
            </div>
            <div class="box">
              <div class="box-inner img-box" :style="{ 'background-image': `url(${require('@/assets/img/ueber-uns/geschichte/1890_Stainzerhof.jpg')})` }"></div>
            </div>
          </div>
          <div class="box-column box-column-order" data-year="1880">
            <div class="box">
              <div class="box-inner img-box" :style="{ 'background-image': `url(${require('@/assets/img/ueber-uns/geschichte/1880_Name-STYRIA.jpg')})` }" style="background-position:top center;"></div>
            </div>
            <div class="box">
              <div class="box-inner bg-blue-60perc">
                <div class="txt-box">
                  <h3>
                    <span>{{ $t('geschichte.1880-hl') }}</span>
                  </h3>
                  <p>{{ $t('geschichte.1880-txt') }}</p>
                  <span class="zahl">1880</span>
                </div>
              </div>
            </div>
          </div>
          <div class="box-column" data-year="1870">
            <div class="box">
              <div class="box-inner bg-violet">
                <div class="txt-box">
                  <h3>
                    <span>{{ $t('geschichte.1870-hl') }}</span>
                  </h3>
                  <p>{{ $t('geschichte.1870-txt') }}</p>
                  <span class="zahl">1870</span>
                </div>
              </div>
            </div>
            <div class="box">
              <div class="box-inner img-box" :style="{ 'background-image': `url(${require('@/assets/img/ueber-uns/geschichte/1870_Eroeffnung-Vereinsbuchdruckerei.jpg')})` }" style="background-position:top center;"></div>
            </div>
          </div>
          <div class="box-column box-column-order" data-year="1869">
            <div class="box">
              <div class="box-inner img-box" :style="{ 'background-image': `url(${require('@/assets/img/ueber-uns/geschichte/1869-Alois-Karlon-erster-Direktor-Pressvereinsanstalt.jpg')})` }" style="background-position:top center;"></div>
            </div>
            <div class="box">
              <div class="box-inner bg-mint">
                <div class="txt-box">
                  <h3>
                    <span>{{ $t('geschichte.1869-hl') }}</span>
                  </h3>
                  <p class="txt-small">{{ $t('geschichte.1869-txt') }}</p>
                  <span class="zahl">1869</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="btn-jump-to">
      <div class="btn-jump btn-start" @click="toStart"><Icon :path="mdiChevronLeft" />{{ $t('General.start') }}</div>
      <div class="btn-jump btn-end" @click="toEnd">{{ $t('General.ende') }}<Icon :path="mdiChevronRight" /></div>
    </div>

    <div class="slider-btn-small-device-outer" style="display:none;">
      <div class="slider-btn-small-device slider-btn-next" @click="left"><Icon :path="mdiChevronRight" /></div>
      <div class="slider-btn-small-device slider-btn-prev" @click="right"><Icon :path="mdiChevronLeft" /></div>
    </div>
    <div class="slider-navi-all">
      <div class="slider-navi-viewport" @mouseenter="setOverlay(true)">
        <div class="slider-navi-outer" id="slider-container">
          <div class="slider-bar" id="slider-bar">
            <div class="slider-bar-inner">
              <div class="slider-btn-outer">
                <div class="slider-btn slider-btn-next" @click="left"><Icon :path="mdiChevronRight" /></div>
                <div class="slider-btn slider-btn-prev" @click="right"><Icon :path="mdiChevronLeft" /></div>
              </div>
            </div>
          </div>
          <div class="slider-navi-inner" id="slider-navi-inner">
            <div class="navi-column" data-year="2021"><span class="nr-top">2021</span></div>
            <div class="navi-column" data-year="2021"><span class="nr-bottom">2021</span></div>
            <div class="navi-column" data-year="2019"><span class="nr-top">2019</span></div>
            <div class="navi-column" data-year="2015"><span class="nr-bottom">2015</span></div>
            <div class="navi-column" data-year="2006"><span class="nr-top">2006</span></div>
            <div class="navi-column" data-year="2005"><span class="nr-bottom">2005</span></div>
            <div class="navi-column" data-year="2002"><span class="nr-top">2002</span></div>
            <div class="navi-column" data-year="2001"><span class="nr-bottom">2001</span></div>
            <div class="navi-column" data-year="1997"><span class="nr-top">1997</span></div>
            <div class="navi-column" data-year="1995"><span class="nr-bottom">1995</span></div>
            <div class="navi-column" data-year="1992"><span class="nr-top">1992</span></div>
            <div class="navi-column" data-year="1991"><span class="nr-bottom">1991</span></div>
            <div class="navi-column" data-year="1980"><span class="nr-top">1980</span></div>
            <div class="navi-column" data-year="1977"><span class="nr-bottom">1977</span></div>
            <div class="navi-column" data-year="1960"><span class="nr-top">1960</span></div>
            <div class="navi-column" data-year="1948"><span class="nr-bottom">1948</span></div>
            <div class="navi-column" data-year="1945"><span class="nr-top">1945</span></div>
            <div class="navi-column" data-year="1945"><span class="nr-bottom">1945</span></div>
            <div class="navi-column" data-year="1938"><span class="nr-top">1938</span></div>
            <div class="navi-column" data-year="1912"><span class="nr-bottom">1912</span></div>
            <div class="navi-column" data-year="1904"><span class="nr-top">1904</span></div>
            <div class="navi-column" data-year="1902"><span class="nr-bottom">1902</span></div>
            <div class="navi-column" data-year="1895"><span class="nr-top">1895</span></div>
            <!-- <div class="navi-column" data-year="1890"><span class="nr-bottom">1890</span></div>
            <div class="navi-column" data-year="1880"><span class="nr-top">1880</span></div>
            <div class="navi-column" data-year="1870"><span class="nr-bottom">1870</span></div>
            <div class="navi-column" data-year="1869"><span class="nr-top">1869</span></div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, onUnmounted, ref, watch } from 'vue';

import SocialMediaIconBox from '../../../components/Frontend/SocialMediaIconBox.vue';
import Footer from '../../../components/Frontend/Footer.vue';
import { mdiBeakerAlert, mdiChevronLeft, mdiChevronRight, mdiCursorDefaultClick, mdiInformationOutline } from '@mdi/js';

export default defineComponent({
  meta: {
    de: {
      title: 'Geschichte der STYRIA. Seit 1869.| Styria Media Group',
      keywords: ['unternehmensgeschihte', 'geschichte', 'historie', 'styria', '1869'],
      description: 'Zukunft braucht Herkunft: Die STYRIA bringt ihre Medien seit 1869 den Menschen näher.',
      image: require('@/assets/img/og/ueber-uns.png'),
    },
    en: {
      title: 'History. Since 1869. | Styria Media Group',
      keywords: ['corporate history', 'history', 'styria', '1869'],
      description: 'Future needs origin: STYRIA has been bringing its media closer to the people since 1869.',
      image: require('@/assets/img/og/ueber-uns.png'),
    },
  },
  components: {
    SocialMediaIconBox,
    Footer,
  },
  setup() {
    const active = ref(0);
    const containerwidth = ref(0);
    const boxwidth = ref(0);
    const boxcount = ref(0);
    const itemcount = ref(0);
    const swiping = ref(0);

    const windowsize = ref(window.innerWidth);
    const noslide = computed(() => windowsize.value <= 768);

    const overlayHide = ref(false);
    const toogleOverlay = () => {
      overlayHide.value = !overlayHide.value;
    };
    const setOverlay = (value: boolean) => {
      overlayHide.value = value;
    };

    let sliderbar: HTMLElement | null;
    let yearbar: HTMLElement | null;
    let yearcontainer: HTMLElement | null;

    const translate = computed(() => {
      if (noslide.value) return 0;
      if (boxwidth.value > 0) {
        let offset = 0;
        if (swiping.value == 1) {
          offset = -50;
        } else if (swiping.value == -1) {
          offset = 50;
        }
        return active.value * boxwidth.value + offset;
      }

      return 0;
    });

    const percentscrolled = computed(() => {
      return (active.value * boxwidth.value) / ((boxwidth.value * (boxcount.value - itemcount.value)) / 100); // current % * (100% / 100)
    });

    const yearoffset = (base: number) => {
      if (percentscrolled.value < 50) {
        return (base * percentscrolled.value) / 50;
      } else if (percentscrolled.value > 50) {
        return -1 * ((base * (100 - percentscrolled.value)) / 50);
      } else {
        return base;
      }
    };

    const updateslider = () => {
      if (sliderbar != null && yearbar != null && yearcontainer != null) {
        const sliderbarbase = sliderbar.clientWidth / 2;
        const yearbarbase = 150 / 2 - sliderbarbase;

        sliderbar.style.left = `${percentscrolled.value}%`;

        if (percentscrolled.value <= 50) {
          sliderbar.style.transform = `translateX(-${(percentscrolled.value / 100) * sliderbar.clientWidth - yearoffset(sliderbarbase)}px)`;
          yearbar.style.transform = `translateX(-${(percentscrolled.value / 100) * (yearbar.clientWidth - yearcontainer.clientWidth) + (yearbarbase - (yearbarbase * percentscrolled.value) / 50 - sliderbar.clientWidth / 2)}px)`;
        } else {
          sliderbar.style.transform = `translateX(-${(percentscrolled.value / 100) * sliderbar.clientWidth - yearoffset(sliderbarbase)}px)`;
          yearbar.style.transform = `translateX(-${(percentscrolled.value / 100) * (yearbar.clientWidth - yearcontainer.clientWidth) + (yearbarbase - (yearbarbase * percentscrolled.value) / 50 + sliderbar.clientWidth / 2)}px)`;
        }
      }
    };
    watch(active, updateslider);

    const right = () => {
      if (active.value > 0) {
        active.value--;
        //updateslider();
        setOverlay(true);
      }
    };

    const left = () => {
      if (active.value < boxcount.value - itemcount.value) {
        active.value++;
        // updateslider();
        setOverlay(true);
      }
    };

    const toEnd = () => {
      active.value = boxcount.value - itemcount.value;
      setOverlay(true);
    };

    const toStart = () => {
      active.value = 0;
      setOverlay(true);
    };

    const handlekey = (e: KeyboardEvent) => {
      switch (e.key) {
        case 'ArrowLeft':
          right();
          break;
        case 'ArrowRight':
          left();
          break;
      }
    };

    const init = () => {
      const elements = document.getElementsByClassName('box-column');
      const container = document.getElementById('slider-inner');
      const viewport = document.getElementById('slider-outer');
      sliderbar = document.getElementById('slider-bar');
      yearbar = document.getElementById('slider-navi-inner');
      yearcontainer = document.getElementById('slider-container');

      if (elements.length > 0 && container && viewport && yearbar) {
        if (noslide.value) {
          boxwidth.value = elements[0].clientWidth;
          boxcount.value = elements.length;
          container.style.width = `100%`;
          containerwidth.value = boxcount.value * boxwidth.value;
          itemcount.value = viewport.clientWidth / boxwidth.value;
          yearbar.style.width = `${(boxcount.value - itemcount.value + 1) * 150}px`;
          updateslider();
        } else {
          boxwidth.value = elements[0].clientWidth;
          boxcount.value = elements.length;
          container.style.width = `${boxcount.value * boxwidth.value}px`;
          containerwidth.value = boxcount.value * boxwidth.value;
          itemcount.value = viewport.clientWidth / boxwidth.value;
          yearbar.style.width = `${(boxcount.value - itemcount.value + 1) * 150}px`;
          updateslider();
        }
      }
      window.addEventListener('keyup', handlekey);
    };

    let touchstartx = 0;

    const unify = (e: TouchEvent | MouseEvent) => {
      return (e as TouchEvent).changedTouches ? (e as TouchEvent).changedTouches[0] : (e as MouseEvent);
    };

    const touchstart = (e: TouchEvent | MouseEvent) => {
      if (noslide.value) return;
      touchstartx = unify(e).clientX;
    };

    const touchend = (e: TouchEvent | MouseEvent) => {
      if (noslide.value) return;
      if (touchstartx || touchstartx === 0) {
        if (Math.abs(unify(e).clientX - touchstartx) > 50) {
          const direction = Math.sign(unify(e).clientX - touchstartx);
          if (direction == -1) {
            left();
          } else if (direction == 1) {
            right();
          }
        }
        swiping.value = 0;
        touchstartx = 0;
      }
    };

    const touchmove = (e: TouchEvent | MouseEvent) => {
      if (noslide.value) return;
      e.preventDefault();
      if (touchstartx || touchstartx != 0) {
        if (Math.abs(unify(e).clientX - touchstartx) > 50) {
          swiping.value = Math.sign(unify(e).clientX - touchstartx);
        }
      }
    };

    const handleResize = () => {
      windowsize.value = window.innerWidth;
      init();
    };

    window.addEventListener('resize', handleResize);

    const cleanup = () => {
      window.removeEventListener('keyup', handlekey);
      window.removeEventListener('resize', handleResize);
    };

    onMounted(init);
    onUnmounted(cleanup);

    return {
      windowsize,
      mdiChevronLeft,
      translate,
      mdiChevronRight,
      mdiCursorDefaultClick,
      mdiInformationOutline,
      left,
      right,
      touchstart,
      touchend,
      touchmove,
      toEnd,
      toStart,
      swiping,
      toogleOverlay,
      overlayHide,
      setOverlay,
    };
  },
});
</script>
